
























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Contact } from '@tada-team/tdproto-ts'
import { rootStore, contactsStore } from '@/store'

interface Item {
  title: string
  value: boolean
  disable: boolean,
  handler: (val: boolean) => void
}

@Component
export default class ContactProfileNotifications extends Vue {
  @Prop({
    required: true,
    type: Object,
  }) profile!: Contact

  private isMeetingNotificationEnabled = false
  private isTaskNotificationEnabled = false
  private isGroupNotificationEnabled = false
  private isAllNotificationEnabled = false
  private isSystembotNotificationEnabled = false
  private isReactionsNotificationEnabled = false

  created () {
    this.updatedNotificationsToggle()
    this.isAllNotificationEnabled = rootStore.state.isNotificationsAllowed
  }

  @Watch('profile')
  changeProfile () {
    this.updatedNotificationsToggle()
  }

  private updatedNotificationsToggle () {
    this.isMeetingNotificationEnabled = !!this.profile.meetingNotificationsEnabled
    this.isTaskNotificationEnabled = !!this.profile.taskNotificationsEnabled
    this.isGroupNotificationEnabled = !!this.profile.groupNotificationsEnabled
    this.isSystembotNotificationEnabled = !!this.profile.systembotNotificationsEnabled
    this.isReactionsNotificationEnabled = !!this.profile.reactionNotificationsEnabled
  }

  get items (): Item[] {
    return [{
      title: this.$t('profiles.notifications.all').toString(),
      value: this.isNotificationEnabled,
      disable: false,
      handler: this.onChangeNotifications,
    }, {
      title: this.$t('profiles.notifications.groups').toString(),
      value: this.groupNotifications,
      disable: !this.isNotificationEnabled,
      handler: this.onChangeGroupNotifications,
    }, {
      title: this.$t('profiles.notifications.tasks').toString(),
      value: this.taskNotifications,
      disable: !this.isNotificationEnabled,
      handler: this.onChangeTaskNotifications,
    }, {
      title: this.$t('profiles.notifications.meetings').toString(),
      value: this.meetingNotifications,
      disable: !this.isNotificationEnabled,
      handler: this.onChangeMeetingNotifications,
    }, {
      title: this.$t('profiles.notifications.systembot').toString(),
      value: this.systembotNotifications,
      disable: !this.isNotificationEnabled,
      handler: this.onChangeSystembotNotifications,
    }, {
      title: this.$t('profiles.notifications.reactions').toString(),
      value: this.reactionsNotifications,
      disable: !this.isNotificationEnabled,
      handler: this.onChangeReactionsNotifications,
    },
    ]
  }

  get isNotificationEnabled () {
    return this.isAllNotificationEnabled
  }

  get groupNotifications () {
    return this.isGroupNotificationEnabled
  }

  get taskNotifications () {
    return this.isTaskNotificationEnabled
  }

  get meetingNotifications () {
    return this.isMeetingNotificationEnabled
  }

  get systembotNotifications () {
    return this.isSystembotNotificationEnabled
  }

  get reactionsNotifications () {
    return this.isReactionsNotificationEnabled
  }

  async onChangeNotifications (value: boolean) {
    this.isAllNotificationEnabled = value
    try {
      await rootStore.actions.setNotificationsAllowed(value)
    } catch (e) {
      setTimeout(() => {
        this.isAllNotificationEnabled = rootStore.state.isNotificationsAllowed
      }, 300)
      throw e
    }
  }

  async onChangeGroupNotifications (value: boolean) {
    this.isGroupNotificationEnabled = value
    try {
      await contactsStore.actions.editContact({
        jid: this.profile.jid,
        params: { group_notifications_enabled: value },
      })
    } catch (e) {
      setTimeout(() => {
        this.isGroupNotificationEnabled = !!this.profile.groupNotificationsEnabled
      }, 300)
      throw e
    }
  }

  async onChangeTaskNotifications (value: boolean) {
    this.isTaskNotificationEnabled = value
    try {
      await contactsStore.actions.editContact({
        jid: this.profile.jid,
        params: { task_notifications_enabled: value },
      })
    } catch (e) {
      setTimeout(() => {
        this.isTaskNotificationEnabled = !!this.profile.taskNotificationsEnabled
      }, 300)
      throw e
    }
  }

  async onChangeMeetingNotifications (value: boolean) {
    this.isMeetingNotificationEnabled = value
    try {
      await contactsStore.actions.editContact({
        jid: this.profile.jid,
        params: { meeting_notifications_enabled: value },
      })
    } catch (e) {
      setTimeout(() => {
        this.isMeetingNotificationEnabled = !!this.profile.meetingNotificationsEnabled
      }, 300)
      throw e
    }
  }

  async onChangeSystembotNotifications (value: boolean) {
    this.isSystembotNotificationEnabled = value

    if (value) {
      window.goal('userProfileSettings', { myProfileSetings: 'ВКЛ пуши Тадабот' })
    } else {
      window.goal('userProfileSettings', { myProfileSetings: 'ВЫКЛ пуши Тадабот' })
    }

    try {
      await contactsStore.actions.editContact({
        jid: this.profile.jid,
        params: { systembot_notifications_enabled: value },
      })
    } catch (e) {
      setTimeout(() => {
        this.isSystembotNotificationEnabled = !!this.profile.systembotNotificationsEnabled
      }, 300)
      throw e
    }
  }

  async onChangeReactionsNotifications (value: boolean) {
    this.isReactionsNotificationEnabled = value

    if (value) {
      window.goal('userProfileSettings', { myProfileSetings: 'ВКЛ пуши на реакции' })
    } else {
      window.goal('userProfileSettings', { myProfileSetings: 'ВЫКЛ пуши на реакции' })
    }

    try {
      await contactsStore.actions.editContact({
        jid: this.profile.jid,
        params: { reaction_notifications_enabled: value },
      })
    } catch (e) {
      setTimeout(() => {
        this.isReactionsNotificationEnabled = !!this.profile.reactionNotificationsEnabled
      }, 300)
      throw e
    }
  }
}
